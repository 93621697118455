import React from 'react'
import AboutMe from '../../components/AboutMe/AboutMe'
import './Info.css'

const Info = () => {
  return (
    <div className='page-container'>
      <AboutMe />
    </div>
  )
}

export default Info
